import React from "react";
import "./paypal.scss";

const PayPalButton = () => {
  return (
    <div>
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="57UB4EKGK8G52" />
        <button className="paypal__button" type="submit" value="Donate">
          <img
            className="paypal__image"
            src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png"
          />
          Donate
        </button>
      </form>
    </div>
  );
};

export default PayPalButton;
