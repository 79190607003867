import React, { useState } from "react";
import cn from "classnames";
import "./snackbar.scss";
import PayPalButton from "../paypal/paypal";

const SnackBar = () => {
  const [active, setActive] = useState(
    JSON.parse(localStorage.getItem("showDonation")) === null
      ? true
      : JSON.parse(localStorage.getItem("showDonation"))
  );

  const closeForever = () => {
    localStorage.setItem("showDonation", false);
    setActive(false);
  };

  return (
    <React.Fragment>
      <div className={cn("snackbar", { hide: !active })}>
        <p>Help keeping this site alive by donating a small amount</p>
        <PayPalButton />
        <button className="hide__button" onClick={closeForever}>Don't show again</button>
      </div>
    </React.Fragment>
  );
};

export default SnackBar;
