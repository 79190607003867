import React from "react";
import Sticky from "react-stickynode";
import Slider from "react-rangeslider";
import PropTypes from "prop-types";
import cn from "classnames";
import "./side-bar.scss";
import PayPalButton from "../paypal/paypal";

const SideBar = ({
  itemCount,
  shouldShowFounderItems,
  shouldShowMasteredItems,
  rankImage,
  rankNumber,
  mastery,
  missions,
  totalMissions,
  totalMissionsMastery,
  junctions,
  masteredCount,
  rank,
  updateJunctions,
  showFounder,
  hideMastered,
  updateMissions,
  missionsUpdated,
  junctionsUpdated,
  intrinsics,
  updateIntrinsics,
  intrinsicsUpdated,
  totalIntrinsics,
}) => {
  itemCount = shouldShowFounderItems ? itemCount : itemCount - 3;

  const onIntrinsicsChange = (value) => updateIntrinsics(value);
  const onMissionsChange = (value) => updateMissions(value);
  const onJunctionsChange = (value) => updateJunctions(value);
  return (
    <Sticky className="sticky" top={20}>
      <h1 className="rank">{rankNumber}</h1>
      <img src={rankImage} alt="rank" />
      <div className="rank__info_container">
        <p className="rank__title">{rank}</p>
        <p className="rank__xp">
          mastery:{" "}
          {Math.floor(
            mastery +
              missions * (totalMissionsMastery / totalMissions) +
              junctions * 1000 +
              intrinsics * 1500
          )}
        </p>
        <p className="rank__items">
          items: {masteredCount} / {itemCount}
        </p>
      </div>

      <div className="sliders">
        <button
          className={cn("sidebar__button", {
            "--pressed": shouldShowFounderItems,
          })}
          onClick={() => showFounder()}
        >
          {shouldShowFounderItems ? "hide founder" : "show founder"}
        </button>

        <button
          className={cn("sidebar__button", {
            "--pressed": shouldShowMasteredItems,
          })}
          onClick={() => hideMastered()}
        >
          {shouldShowMasteredItems ? "hide mastered" : "show mastered"}
        </button>

        <p className="missions">missions: {missions}</p>
        <Slider
          min={0}
          max={totalMissions}
          step={1}
          tooltip={false}
          value={missions}
          onChange={onMissionsChange}
          onChangeComplete={() => missionsUpdated(missions)}
        />
        <p className="rank__items">junctions: {junctions}</p>
        <Slider
          min={0}
          max={26}
          step={1}
          tooltip={false}
          value={junctions}
          onChange={onJunctionsChange}
          onChangeComplete={() => junctionsUpdated(junctions)}
        />
        <p className="rank__items">intrinsics: {intrinsics}</p>
        <Slider
          min={0}
          max={totalIntrinsics}
          step={1}
          tooltip={false}
          value={intrinsics}
          onChange={onIntrinsicsChange}
          onChangeComplete={() => intrinsicsUpdated(intrinsics)}
        />
        <PayPalButton />
      </div>
    </Sticky>
  );
};

SideBar.propTypes = {
  itemCount: PropTypes.number,
  shouldShowFounderItems: PropTypes.bool,
  shouldShowMasteredItems: PropTypes.bool,
  rankImage: PropTypes.string,
  rankNumber: PropTypes.number,
  mastery: PropTypes.number,
  missions: PropTypes.number,
  totalMissions: PropTypes.number,
  totalMissionsMastery: PropTypes.number,
  junctions: PropTypes.number,
  masteredCount: PropTypes.number,
  rank: PropTypes.number,
  updateJunctions: PropTypes.func,
  showFounder: PropTypes.func,
  hideMastered: PropTypes.func,
  updateMissions: PropTypes.func,
  missionsUpdated: PropTypes.func,
  junctionsUpdated: PropTypes.func,
  intrinsics: PropTypes.number,
  updateIntrinsics: PropTypes.func,
  intrinsicsUpdated: PropTypes.func,
  totalIntrinsics: PropTypes.number,
};

export default SideBar;
