import React from "react";
import ReactDOM from "react-dom";
import Framedex from "./components/framedex";
import Welcome from "./components/welcome-page/welcome-page";
import "./index.scss";
import SnackBar from "./components/snackbar/snackbar";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

ReactDOM.render(
  <BrowserRouter>
    <SnackBar />
    <Switch>
      <Route
        exact
        path="/"
        render={routeProps => (
          <Welcome {...routeProps} noUser={false} apiUrl={apiUrl} />
        )}
      />
      <Route
        exact
        path="/no-user"
        render={routeProps => (
          <Welcome {...routeProps} noUser={true} apiUrl={apiUrl} />
        )}
      />
      <Route
        exact
        path="/:hash"
        render={({ match }) => (
          <Framedex user={match.params.hash} apiUrl={apiUrl} />
        )}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
