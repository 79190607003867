import { useState, useEffect } from "react";

export const useHttp = (query) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState(null);

  const fetchData = async (query) => {
    try {
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error("Failed fetch");
      }
      const data = await response.json()
      setFetchedData(data);
      setIsLoading(false);
    } catch (err) {
      console.error("Oh no, error occured: ", err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(query);
  }, [query]);
  return [isLoading, fetchedData];
};
