import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import "./list-item.scss";
import cn from "classnames";

const apiUrl = process.env.REACT_APP_API_URL;

const handleClick = (
  e,
  user,
  mastered,
  setMastered,
  name,
  mastery,
  adjustMastery,
  adjustListCount,
  masteredCount,
  updateList
) => {
  if (name.includes("&")) {
    name = name.replace("&", "%26");
  }
  e.preventDefault();
  if (e.ctrlKey) {
    window.open(
      "http://warframe.wikia.com/wiki/Special:Search?fulltext=0&search=" + name
    );
  } else {
    if (mastered) {
      fetch(`${apiUrl}/deleteRelation?id=${user}&e=${name}`);
      setMastered(!mastered);
      updateList(name, !mastered);
      adjustMastery(-mastery, -1);
      adjustListCount(masteredCount - 1);
    } else {
      fetch(`${apiUrl}/addRelation?id=${user}&e=${name}`);
      setMastered(!mastered);
      updateList(name, !mastered);
      adjustMastery(mastery, +1);
      adjustListCount(masteredCount + 1);
    }
  }
};

const ListItem = ({
  name,
  shouldShowFounderItems,
  shouldShowMasteredItems,
  founder,
  user,
  mastered,
  mastery,
  adjustMastery,
  adjustListCount,
  masteredCount,
  updateList,
}) => {
  const [itemMastered, setMastered] = useState(mastered);

  return (
    (shouldShowMasteredItems || !itemMastered) &&
    (shouldShowFounderItems || !founder) && (
      <div className="listitem">
        <button
          data-tip={name}
          data-for={name}
          className={cn("button", { "button--pressed": itemMastered })}
          onClick={(e) =>
            handleClick(
              e,
              user,
              itemMastered,
              setMastered,
              name,
              mastery,
              adjustMastery,
              adjustListCount,
              masteredCount,
              updateList
            )
          }
        >
          {name}
        </button>
        <ReactTooltip
          className="tooltip"
          id={name}
          type="info"
          delayShow={500}
          place="top"
          effect="solid"
        >
          <span>Ctrl click for Wiki</span>
        </ReactTooltip>
      </div>
    )
  );
};

ListItem.propTypes = {
  name: PropTypes.string,
  shouldShowFounderItems: PropTypes.bool,
  shouldShowMasteredItems: PropTypes.bool,
  founder: PropTypes.bool,
  user: PropTypes.string,
  mastered: PropTypes.bool,
  mastery: PropTypes.number,
  adjustMastery: PropTypes.func,
  adjustListCount: PropTypes.func,
  masteredCount: PropTypes.number,
  updateList: PropTypes.func,
};

export default ListItem;
