import React, { useState } from "react";
import { Redirect } from "react-router";
import crypto from "crypto";
import "./welcome-page.scss";
import Logo from "../../images/logo.png";

const Welcome = ({ apiUrl, noUser }) => {
  const [redirect, setRedirect] = useState(false);
  const [hash, setHash] = useState(null);

  const handleClick = () => {
    let hash = createHash();
    fetch(`${apiUrl}/addUser?id=${hash}`)
      .then(() => {
        setHash(hash);
        setRedirect(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const createHash = () => {
    var current_date = new Date().valueOf().toString();
    var random = Math.random().toString();
    return crypto
      .createHash("sha1")
      .update(current_date + random)
      .digest("hex");
  };

  if (redirect) {
    return <Redirect to={"/" + hash} />;
  }
  return (
    <div className="welcome">
      <div className="header">
        <img src={Logo} className="logo" alt="logo" />
      </div>
      {noUser ? (
        <div className="no_user">
          <p>The url you entered does not exist</p>
        </div>
      ) : (
        <div />
      )}
      <p>
        Welcome to framedex, your personal companion for your warframe codex.
        Press get started to start filling out your codex, and remember to
        bookmark the page, as it will be your personal url.
      </p>
      <div>
        <button className="getstarted" onClick={() => handleClick()}>
          Get started!
        </button>
      </div>
    </div>
  );
};

export default Welcome;
