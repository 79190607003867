import React, { useState, useEffect, useCallback } from "react";
import List from "./list/list";
import SideBar from "./side-bar/side-bar";
import { withRouter, Redirect } from "react-router";
import Masonry from "react-masonry-component";
import { useHttp } from "./hooks/http";
import { importAll } from "./helpers/image-loader";
import "./framedex.scss";
import "./rangeslider.scss";
const ranks = require("../ranks.json");

const MISSIONS = 466;
const INTRINCICS = 50;
const MISSIONSMASTERY = 29002;
const images = importAll(
  require.context("../images", false, /\.(png|jpe?g|svg)$/)
);

const Framedex = ({ apiUrl, user }) => {
  const [shouldShowMasteredItems, setShowMastered] = useState(
    localStorage.getItem("shouldShowMasteredItems") !== null
      ? JSON.parse(localStorage.getItem("shouldShowMasteredItems"))
      : true
  );
  const [shouldShowFounderItems, setShowFounder] = useState(
    JSON.parse(localStorage.getItem("shouldShowFounderItems"))
  );

  const [mastery, setMastery] = useState(0);
  const [junctions, setJunctions] = useState(0);
  const [intrinsics, setIntrinsics] = useState(0);
  const [missions, setMissions] = useState(0);
  const [masteredCount, setMasteredCount] = useState(0);
  const [rank, setRank] = useState("unranked");
  const [rankNumber, setrankNumber] = useState(0);
  const [rankImage, setRankImage] = useState(images["0.png"]);
  const [itemCount, setItemCount] = useState(0);
  const [isLoading, userData] = useHttp(`${apiUrl}/getUserInfo?id=${user}`);
  var equipment = [];

  const totalMastery = () =>
    mastery +
    missions * (MISSIONSMASTERY / MISSIONS) +
    junctions * 1000 +
    intrinsics * 1500;

  const updateRank = () => {
    Object.keys(ranks).forEach((exp) => {
      if (totalMastery() >= exp) {
        setRank(ranks[exp].rank);
        setRankImage(images[ranks[exp].rankNumber + ".png"]);
        setrankNumber(ranks[exp].rankNumber);
      }
    });
  };

  if (userData) {
    equipment = Object.keys(userData.equipment)
      .map((key) => [key, userData.equipment[key]])
      .sort((first, second) => second[1].length - first[1].length);
  }

  useEffect(() => {
    if (userData) {
      let [mastery, masteredCount, equipmentCount] = [0, 0, 0];
      Object.values(userData.equipment).forEach((type) =>
        type.forEach((item) => {
          mastery += item.mastered && item.mastery;
          masteredCount += item.mastered && 1;
          equipmentCount += 1;
        })
      );
      setMastery(mastery);
      setItemCount(equipmentCount);
      setMasteredCount(masteredCount);
      setMissions(userData.missions);
      setJunctions(userData.junctions);
      setIntrinsics(userData.intrinsics);
    }
  }, [userData]);

  useEffect(() => {
    updateRank();
  }, [updateRank]);

  const hideMastered = () => {
    localStorage.setItem("shouldShowMasteredItems", !shouldShowMasteredItems);
    setShowMastered(!shouldShowMasteredItems);
  };

  const showFounder = () => {
    localStorage.setItem("shouldShowFounderItems", !shouldShowFounderItems);
    setShowFounder(!shouldShowFounderItems);
  };

  const missionsUpdated = (missions) => {
    fetch(`${apiUrl}/updateMissions?id=${user}&val=${missions}`);
  };

  const junctionsUpdated = (junctions) => {
    fetch(`${apiUrl}/updateJunctions?id=${user}&val=${junctions}`);
  };

  const intrinsicsUpdated = (intrinsics) => {
    fetch(`${apiUrl}/updateIntrinsics?id=${user}&val=${intrinsics}`);
  };

  const adjustMastery = (mastery2, adjustment) => {
    setMastery((mastery) => mastery + mastery2);
    setMasteredCount((mastered) => mastered + adjustment);
    updateRank();
  };

  return (
    !isLoading && (
      <>
        {!userData && <Redirect to={"/no-user"} />}
        <div className="content__container">
          <div className="content__container__statistics">
            <SideBar
              rank={rank}
              rankNumber={rankNumber}
              rankImage={rankImage}
              itemCount={itemCount}
              masteredCount={masteredCount}
              mastery={mastery}
              missions={missions}
              junctions={junctions}
              intrinsics={intrinsics}
              totalMissions={MISSIONS}
              totalMissionsMastery={MISSIONSMASTERY}
              shouldShowMasteredItems={shouldShowMasteredItems}
              hideMastered={hideMastered}
              shouldShowFounderItems={shouldShowFounderItems}
              showFounder={showFounder}
              updateMissions={setMissions}
              updateJunctions={setJunctions}
              updateIntrinsics={setIntrinsics}
              missionsUpdated={missionsUpdated}
              junctionsUpdated={junctionsUpdated}
              intrinsicsUpdated={intrinsicsUpdated}
              totalIntrinsics={INTRINCICS}
            />
          </div>
          <div className="header">
            <img src={images["logo.png"]} className="logo" alt="logo" />
          </div>
          <div className="list_container">
            <div>
              <Masonry>
                {equipment.map((type) => (
                  <List
                    key={type}
                    items={type[1]}
                    shouldShowMasteredItems={shouldShowMasteredItems}
                    shouldShowFounderItems={shouldShowFounderItems}
                    adjustMastery={adjustMastery}
                    user={user}
                    type={type[0]}
                    title={type[0]}
                  />
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default withRouter(Framedex);
