import React, { useState } from "react";
import PropTypes from "prop-types";
import ListItem from "../list-item/list-item";
import "./list.scss";

const List = ({
  items,
  shouldShowFounderItems,
  shouldShowMasteredItems,
  title,
  adjustMastery,
  user,
}) => {
  const [listItems, setListItems] = useState(items);
  let nonMastered = listItems.filter((i) => i.mastered !== true);
  let itemcount = shouldShowFounderItems
    ? listItems.length
    : listItems.filter((i) => !i.founder).length;
  const [masteredCount, setMasteredCount] = useState(
    listItems.length - nonMastered.length
  );

  const updateList = (name, mastered) => {
    setListItems(
      listItems.map((listItem) =>
        listItem.name === name ? { ...listItem, mastered: mastered } : listItem
      )
    );
  };

  return (
    <>
      {(shouldShowMasteredItems || masteredCount !== itemcount) && (
        <div className="list">
          <React.Fragment>
            <h2>{title.toUpperCase()}</h2>
            <h3>{masteredCount + "/" + itemcount}</h3>
          </React.Fragment>
          {listItems
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => {
              return (
                <ListItem
                  {...item}
                  updateList={updateList}
                  shouldShowMasteredItems={shouldShowMasteredItems}
                  shouldShowFounderItems={shouldShowFounderItems}
                  adjustMastery={adjustMastery}
                  adjustListCount={setMasteredCount}
                  masteredCount={masteredCount}
                  type={title}
                  user={user}
                  key={item.name}
                />
              );
            })}
        </div>
      )}
    </>
  );
};

List.propTypes = {
  items: PropTypes.array,
  shouldShowFounderItems: PropTypes.bool,
  shouldShowMasteredItems: PropTypes.bool,
  title: PropTypes.string,
  adjustMastery: PropTypes.func,
  user: PropTypes.string,
};

export default List;
